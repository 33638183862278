<template>
    <div
        v-if="errors && errors.length > 0"
        class="form-errors"
    >
        <ul
            class="form-errors__list"
        >
            <li
                v-for="error in errors"
                :key="error"
                class="form-errors__item"
            >
                {{ error }}
            </li>
        </ul>

        <div class="form-errors__dismiss">
            <BaseButton
                type="button"
                icon="close"
                icon-position="center"
                class="base-button--clean"
                @click="$emit('dismissErrors')"
            />
        </div>
    </div>
</template>

<script setup>
defineProps({
    errors: {
        type: Array,
        default: () => [],
    }
});

defineEmits(['dismissErrors']);
</script>

<style lang="less" src="./FormErrors.less" />
